@import "mixins/breakpoints";
@import "variables";

.js-moments-hero {
  position: relative;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.moments-hero__video-div {
  width: 100%;
}

.moments-hero__desktop-video {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
    width: 100%;
  }
}

.moments-hero__mobile-video {
  display: block;
  width: 100%;
  opacity: 0.6;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.moments-hero__content {
  padding-top: 30px;
  padding-bottom: 40px;
  width: 100%;
  position: absolute;
  z-index: 999;
}

.moments-hero__content__empty {
  display: inline-block;
  width: 58%;

  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.moments-hero__content__inner {
  display: inline-block;
  width: 42%;

  @include media-breakpoint-down(sm) {
    width: fit-content;
    display: inline;
  }
}

.moments-hero__main {
  padding-top: 50px;
}

.moments-hero__main-title-badge {
  display: flex;
  justify-content: center;

  svg {
    width: 15em;
    height: 5em;
  }
}

// Downloads
.moments-hero__download__desktop {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.moments-hero__download-link {
  display: flex;
  justify-content: center;
  flex-flow: column;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.moments-hero__download {
  display: none;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: flex-start;
  }
}

.moments-hero__email-link {
  width: fit-content;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  border: 2px solid #4e5363;
  border-radius: 4px;
  padding: 2px;
  background-color: $moments-martinique;
}

.moments-hero__hidden {
  display: none;
}

.moments-hero__success-message {
  text-align: center;
  color: white;
}

.moments-hero__email-description {
  padding-top: 10px;
  text-align: center;
  color: white;
}

.moments-hero__download-email {
  padding: 14px 10px;
  background-color: $moments-martinique;
  border: none;
  color: white;
}

.moments-hero__download-email-button {
  padding: 14px 10px;
}

.moments-hero__download-description--mobile {
  text-align: center;
  color: $moments-hawkes-blue;
}

.moments-hero__download-description--desktop {
  text-align: left;
  color: $moments-hawkes-blue;
  padding-top: 10px;
}

.moments-hero__title-rotate--option {
  display: none;
}

.moments-hero__title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }

  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }

  .moments-hero__title-fixed {}

  .moments-hero__title-rotate {}

  .rotating-word {
    display: none;
  }

  .rotating-word.active {
    display: block;
  }
}

.moments-hero__message {
  text-align: left;
  font-size: 2.4rem;
  width: 80%;
  max-width: 500px;

  @include media-breakpoint-down(sm) {
    text-align: center;
    font-size: 2.4rem;
    width: 70%;
    margin: auto;
  }
}

.moments-hero__video-image-replacement--container {
  margin-top: 100px;

  @include media-breakpoint-up(md) {
    margin-top: 100px;
  }
}

.moments-hero__video-image-replacement {
  width: 100%;
}

.moments-hero__form-input {
  align-self: center;
  background-color: $moments-martinique;
}

.moments-hero__form-action {
  align-self: center;
}

.moments-hero__validation_failed {
  border-color: red;
}
